import React from "react"
import styled from "styled-components"
import { Container } from "react-bootstrap"

import { Link } from "gatsby"
import { FaFacebookSquare } from "@react-icons/all-files/fa/FaFacebookSquare"
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin"
import { FaYoutube } from "@react-icons/all-files/fa/FaYoutube"
import { FaInstagramSquare } from "@react-icons/all-files/fa/FaInstagramSquare"

export default function SocialFooter() {
  return (
    <>
      <section className="themes__panel-light">
        <Container>
          <FooterContainer>
            <ContactContainer>
              <a
                href="https://app.mrpaulxavier.com/login"
                className="social-footer__links"
                target="_blank"
                rel="noreferrer"
              >
                Student Login
              </a>
              <Link to="/" className="social-footer__links">
                Contact
              </Link>
            </ContactContainer>
            <SocialContainer>
              <a
                href="https://www.linkedin.com/in/mrpaulxavier/"
                title="LinkedIn"
                target="_blank"
                rel="noreferrer"
              >
                <FaLinkedin className="social-footer__icons" />
              </a>
              <a
                href="https://www.youtube.com/mrpaulxavier"
                title="YouTube"
                target="_blank"
                rel="noreferrer"
              >
                <FaYoutube className="social-footer__icons" />
              </a>
              <a
                href="https://www.instagram.com/mrpaulxavier/"
                title="InstaGram"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagramSquare className="social-footer__icons" />
              </a>
              <a
                href="https://www.facebook.com/mrpaulxavier"
                title="Facebook"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebookSquare className="social-footer__icons" />
              </a>
            </SocialContainer>
          </FooterContainer>
        </Container>
      </section>
    </>
  )
}

const FooterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background: white;
  height: 50px;
  /* width: 100vw; */
  height: 100px;
  margin: 0;
  padding: 0;

  @media screen and (max-width: 768px) {
    justify-content: space-evenly;
    margin-bottom: 25px;
  }
`
const ContactContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`
const SocialContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-right: 25px;
  height: 50px;
  width: 30%;
`
