import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Container, Navbar, NavDropdown } from "react-bootstrap"
import { FaBars } from "@react-icons/all-files/fa/FaBars"
import Logo from "../../assets/images/PXI-Logo-1.png"
import { menuData } from "../../data/MenuData"
import TagManager from "react-gtm-module"

// console.log(menuData)

export const NavigationBar = ({ toggle }) => {
  // const tagManagerArgs = {
  //   gtmId: "GTM-K6GWVF",
  // }

  // TagManager.initialize(tagManagerArgs)
  return (
    <>
      <ContentWrapper>
        <ContentContainer>
          <div>
            <LogoLink to="/">
              <img src={Logo} height="50px" alt="Mr. Paul Xavier Logo" />
            </LogoLink>
            <Bars onClick={toggle} />
          </div>
          <NavLinksContainer>
            <NavMenu>
              <Navbar>
                <Container style={{ margin: "0px", padding: "0px" }}>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    {menuData.map(item => (
                      <NavDropdown
                        title={item.title}
                        id={item.id}
                        key={item.id}
                      >
                        {item.links.map(item => (
                          <NavDropdown.Item
                            href={item.link}
                            target={item.target}
                            rel={item.rel}
                            key={item.id}
                          >
                            {item.title}
                          </NavDropdown.Item>
                        ))}
                      </NavDropdown>
                    ))}
                  </Navbar.Collapse>
                  <a
                    href="https://www.contentcreator.com/login"
                    className="nav-link"
                    target="blank"
                    rel="noreferrer"
                  >
                    Login
                  </a>
                </Container>
              </Navbar>
            </NavMenu>
          </NavLinksContainer>
        </ContentContainer>
      </ContentWrapper>
    </>
  )
}

const ContentWrapper = styled.div`
  display: flex;
  position: fixed;
  padding: 0;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 100%;
  background: black;
  z-index: 4000;
`

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px;
  max-width: 1280px;
  width: 100%;
`

const NavLinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  color: white;
  width: 80%;
  display: flex;
  justify-content: flex-end;
  align-content: center;
  text-decoration: none;
  /* padding-right: 30px; */
  cursor: pointer;
`

const Bars = styled(FaBars)`
  display: none;
  color: white;
  margin-left: 10px;

  @media screen and (max-width: 768px) {
    display: block;
    margin-top: 5px;
    margin-left: 0px;
    font-size: 1.8rem;
    cursor: pointer;
  }
`
const LogoLink = styled(Link)`
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  height: 100%;
  cursor: pointer;
  z-index: 5;

  @media screen and (max-width: 768px) {
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 30px;
  }
`
const NavMenu = styled.div`
  display: flex;
  align-items: center;
  /* margin-right: 120px; */

  @media screen and (max-width: 768px) {
    display: none;
  }
`
