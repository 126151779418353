import React from "react"
import styled from "styled-components"
import { Container } from "react-bootstrap"

import { Link } from "gatsby"

export default function DisclosureFooter(theme) {
  return (
    <>
      <section className={theme}>
        <Container>
          <DisclosureFooterContainer className={theme}>
            <DisclosureContentLeft>
              <Link to="/privacy-policy" className="disclosure-footer__links">
                Privacy Policy
              </Link>
              <Link
                to="/earnings-disclaimer"
                className="disclosure-footer__links"
              >
                Earnings Disclaimer
              </Link>
              <Link
                to="/terms-and-conditions"
                className="disclosure-footer__links"
              >
                Terms
              </Link>
              <Link to="/about" className="disclosure-footer__links">
                About
              </Link>
            </DisclosureContentLeft>
            <DisclosureContentRight>
              &copy; Copyright 2020
            </DisclosureContentRight>
          </DisclosureFooterContainer>
        </Container>
      </section>
    </>
  )
}

const DisclosureFooterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background: black;
  height: 50px;
  height: 100px;
  margin: 0;
  padding: 0;

  @media screen and (max-width: 768px) {
    justify-content: center;
  }
`
const DisclosureContentLeft = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 10px;
`
const DisclosureContentRight = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 15px;
  color: white;
  font-size: clamp(0.8rem, 2vw, 1rem);
`
